<template>
	<Transition name="slide-bottom-fast">
		<div
			v-if="showCart"
			class="fixed left-0 z-20 w-full px-2 bottom-2"
		>
			<button
				class="flex items-center justify-between w-full px-5 py-2 text-white bg-green-500 rounded-full shadow-lg bottom-3 md:hidden"
				@click.prevent="handleClick"
			>
				<span class="px-2 border border-white rounded">
					{{ numberOfItems }}
				</span>
				<span class="text-lg font-bold">
					Your Cart
				</span>
				<span class="font-normal">
					${{ subtotal }}
				</span>
			</button>
		</div>
	</Transition>
</template>

<script async>
import { mapGetters, mapMutations } from 'vuex'

import { CART_SLIDE_OVER } from '@/constants/slideOver/names.js'

export default {
	beforeRouteLeave() {
		this.loaded = false
	},
	props: {
		cart: {
			type: Object,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		logo: {
			type: String,
			required: true
		},
		url: {
			type: String,
			required: true
		},
		listingId: {
			type: String,
			required: true
		},
		settings: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			CART_SLIDE_OVER,
			loaded: false
		}
	},
	computed: {
		subtotal() {
			return this.cart?.subTotal?.toFixed(2) || 0
		},
		numberOfItems() {
			return this.cart?.numItems || 0
		},
		...mapGetters('slideOver', [ 'activeSlideOverId' ]),
		showCart() {
			return this.loaded && this.activeSlideOverId !== CART_SLIDE_OVER && this.settings?.hasOrdering
		}
	},
	watch: {
		'$route' () {
			this.loaded = false
		}
	},
	updated() {
		this.loaded = true
	},
	mounted() {
		this.loaded = true
	},
	methods: {
		...mapMutations('slideOver', [ 'showSlideOver' ]),
		...mapMutations('cart', [ 'updateTemporaryCartData' ]),
		handleClick() {
			this.updateTemporaryCartData({
				items: this.cart?.items?.map(item => {
					return {
						...item,
						product_id: item.productId
					}
				}) || [],
				num_items: this.cart?.numItems,
				subtotal: this.cart?.subTotal,
				name: this.name,
				logo: this.logo,
				url: this.url,
				listingId: this.listingId,
				settings: this.settings
			})
			this.showSlideOver(CART_SLIDE_OVER)
		}
	}
}
</script>
